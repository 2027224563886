import { useEffect, useState } from "react";
import {
  Col,
  Row,
  Card,
  Tabs,
  Tab,
  Form,
  Alert,
  Button,
} from "react-bootstrap";
import "./DeployGameServer.css";
import Navigation from "../../../components/Navigation/Navigation";
import minecraftLogo from "../../../images/design/minecraft-block.png";
import sampLogo from "../../../images/design/samp-logo.png";
import productService from "../../../services/product.service";
import fivemLogo from "../../../images/design/fivem-logo.png";

export const DeployGameServer = () => {
  const [packages, setPackages] = useState({
    minecraft: [],
    samp: [],
    fivem: []
  });

  const [selectedPackage, setSelectedPackage] = useState(null);
  const [serverLabel, setServerLabel] = useState("");
  const [disableCheckout, setDisableCheckout] = useState(true);
  const [checkOutError, setCheckoutError] = useState(false);
  const [selectedTab, setSelectedTab] = useState("samp");
  const [formFields, setFormFields] = useState({});

  const gameConfigurations = {
    minecraft: [
      {
        name: "Variant",
        type: "select",
        options: ["Vanilla", "Spigot"],
        default: "Vanilla",
      },
    ],
    samp: [
      {
        name: "Version",
        type: "select",
        options: ["0.3.7", "0.3z", "0.3x"],
        default: "0.3.7",
      },
    ],
    fivem: [
      {
        name: "FIVEM_LICENSE",
        note: "Required to start the server. Get your keys at https://keymaster.fivem.net",
        type: "text",
        default: "",
      },
      {
        name: "TXADMIN_ENABLE",
        type: "select",
        options: [1, 0],
        default: 0,
        note: "Select 1 to enable txAdmin, Curios about txAdmin? See: https://txadmin.gg. If enabled it takes some additional time to setup your server.",
      },
    ],
  };

  const getGameServerPackages = async () => {
    try {
      const products = await productService.getProductsByType("gaming");
      const productsData = products.data.filter(
        (product) => product.status !== "inactive"
      );

      const productPackagesByGame = {
        minecraft: [],
        samp: [],
        fivem: [],
      };

      productsData.forEach((product) => {
        const { vendor_product_id } = product;
        if (vendor_product_id.includes("minecraft")) {
          productPackagesByGame.minecraft.push(product);
        } else if (vendor_product_id.includes("samp")) {
          productPackagesByGame.samp.push(product);
        } else if (vendor_product_id.includes("fivem")) {
          productPackagesByGame.fivem.push(product);
        }
      });

      setPackages(productPackagesByGame);
    } catch (error) {
      console.error("Error fetching game server packages:", error);
    }
  };

  const selectPackage = (packageId) => {
    const allPackages = Object.values(packages).flat();
    const packageToSelect =
      allPackages.find((pkg) => pkg.id === packageId) || null;

    setSelectedPackage(packageToSelect);
    setDisableCheckout(!packageToSelect);
    setServerLabel("");
    setFormFields((prev) => {
      const config = gameConfigurations[selectedTab] || [];
      const initialFields = config.reduce((acc, field) => {
        acc[field.name] = field.default || "";
        return acc;
      }, {});
      return initialFields;
    });
  };

  const createOrder = () => {
    if (serverLabel.length < 4 || !selectedPackage) {
      setCheckoutError(true);
      return;
    }

    setCheckoutError(false);
    const serverDetails = [
      {
        serverLabel: serverLabel,
        productType: "gaming",
        planId: selectedPackage.id,
        details: JSON.stringify(formFields),
      },
    ];

    localStorage.setItem("temp_order_details", JSON.stringify(serverDetails));
    window.location.href = "/checkout";
  };

  const renderDynamicFields = () => {
    const fields = gameConfigurations[selectedTab] || [];
    return fields.map((field) => (
      <Form.Group
        className="col-6"
        controlId={`form${field.name.replace(/\s+/g, "")}`}
        key={field.name}
      >
        {field.type !== "checkbox" && <Form.Label>{field.name}</Form.Label>}
        {field.type === "select" ? (
          <Form.Control
            as="select"
            value={formFields[field.name] || ""}
            onChange={(e) =>
              setFormFields({ ...formFields, [field.name]: e.target.value })
            }
          >
            {field.options.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </Form.Control>
        ) : field.type === "checkbox" ? (
          <Form.Check
            className="mt-2"
            type="checkbox"
            checked={!!formFields[field.name]}
            onChange={(e) =>
              setFormFields({ ...formFields, [field.name]: e.target.checked })
            }
            label={`Enable ${field.label}`}
          />
        ) : (
          <Form.Control
            type={field.type}
            value={formFields[field.name] || ""}
            onChange={(e) =>
              setFormFields({ ...formFields, [field.name]: e.target.value })
            }
            placeholder={`Enter ${field.name.toLowerCase()}`}
          />
        )}
        {field.note && <small>{field.note}</small>}
      </Form.Group>
    ));
  };

  useEffect(() => {
    getGameServerPackages();
  }, []);

  return (
    <div className="content">
      <Navigation />
      <div id="main" className="px-3 mt-5">
        <h4 className="mt-3 page-title">Deploy Game Server</h4>
        <Row className="mt-4">
          <Col md={12}>
            <Card className="shadow-sm">
              <Card.Body>
                <h3 className="color-primary mt-2 text-center">
                  Choose Your Game
                </h3>
                <Tabs
                  activeKey={selectedTab}
                  onSelect={(k) => setSelectedTab(k)}
                  id="gameServerSelector"
                  className="mb-3"
                  justify
                >
                  <Tab
                    eventKey="minecraft"
                    title={
                      <>
                        <img
                          src={minecraftLogo}
                          alt="Minecraft"
                          className="img-fluid"
                        />
                        <p>Minecraft</p>
                      </>
                    }
                  >
                    <Row>
                      {packages.minecraft.map((pack) => {
                        const specs = JSON.parse(pack.specifications);
                        return (
                          <div
                            key={pack.vendor_product_id}
                            className={`col-md-4 col-sm-6 mb-4 cursor-pointer`}
                            onClick={() => selectPackage(pack.id)}
                          >
                            <div
                              className={`card shadow-sm  ${
                                selectedPackage?.id === pack.id
                                  ? "selectedPackage"
                                  : ""
                              }`}
                            >
                              <div className="card-body text-center">
                                <h5 className="card-title fw-bold">
                                  {pack.name}
                                </h5>
                                <ul className="list-unstyled mb-3">
                                  <li>
                                    <strong>RAM:</strong> {specs.memory} MB
                                  </li>
                                  <li>
                                    <strong>CPU:</strong> {specs.cpu} vCores
                                  </li>
                                  <li>
                                    <strong>Disk Space:</strong>{" "}
                                    {specs.disk / 1000} GB
                                  </li>
                                </ul>
                                <div>
                                  <h4 className="price">€{pack.price}</h4>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </Row>
                    {renderDynamicFields()}
                  </Tab>
                  <Tab
                    eventKey="samp"
                    title={
                      <>
                        <img src={sampLogo} alt="SA-MP" className="img-fluid" />
                        <p>SA-MP</p>
                      </>
                    }
                  >
                    <Row>
                      {packages.samp.map((pack) => {
                        const specs = JSON.parse(pack.specifications);
                        return (
                          <div
                            key={pack.vendor_product_id}
                            className={`col-md-4 col-sm-6 mb-4 cursor-pointer`}
                            onClick={() => selectPackage(pack.id)}
                          >
                            <div
                              className={`card shadow-sm  ${
                                selectedPackage?.id === pack.id
                                  ? "selectedPackage"
                                  : ""
                              }`}
                            >
                              <div className="card-body text-center">
                                <h5 className="card-title fw-bold">
                                  {pack.name}
                                </h5>
                                <ul className="list-unstyled mb-3">
                                  <li>
                                    <strong>RAM:</strong> {specs.memory} MB
                                  </li>
                                  <li>
                                    <strong>CPU:</strong> {specs.cpu / 100}{" "}
                                    vCores
                                  </li>
                                  <li>
                                    <strong>Disk Space:</strong>{" "}
                                    {specs.disk / 1000} GB
                                  </li>
                                </ul>
                                <div>
                                  <h4 className="price">€{pack.price}</h4>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </Row>
                    {renderDynamicFields()}
                  </Tab>
                  <Tab
                    eventKey="fivem"
                    title={
                      <>
                        <img
                          src={fivemLogo}
                          alt="FiveM"
                          className="img-fluid"
                        />
                        <p>FiveM</p>
                      </>
                    }
                  >
                    <Row>
                      {packages.fivem.map((pack) => {
                        const specs = JSON.parse(pack.specifications);
                        return (
                          <div
                            key={pack.vendor_product_id}
                            className={`col-md-4 col-sm-6 mb-4 cursor-pointer`}
                            onClick={() => selectPackage(pack.id)}
                          >
                            <div
                              className={`card shadow-sm  ${
                                selectedPackage?.id === pack.id
                                  ? "selectedPackage"
                                  : ""
                              }`}
                            >
                              <div className="card-body text-center">
                                <h5 className="card-title fw-bold">
                                  {pack.name}
                                </h5>
                                <ul className="list-unstyled mb-3">
                                  <li>
                                    <strong>RAM:</strong> {specs.memory} MB
                                  </li>
                                  <li>
                                    <strong>CPU:</strong> {specs.cpu / 100}{" "}
                                    vCores
                                  </li>
                                  <li>
                                    <strong>Disk Space:</strong>{" "}
                                    {specs.disk / 1000} GB
                                  </li>
                                </ul>
                                <div>
                                  <h4 className="price">€{pack.price}</h4>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </Row>
                    {renderDynamicFields()}
                  </Tab>
                </Tabs>
                {selectedPackage && (
                  <div className="row mt-5">
                    {checkOutError && (
                      <Alert variant="danger">
                        <strong>Error:</strong> Ensure required fields are
                        filled in.
                      </Alert>
                    )}
                    <Form.Group>
                      <p>Server Name</p>
                      <small>
                        This is the server name visible on the panel, not for
                        public.
                      </small>
                      <Row>
                        <Col md={6}>
                          <Form.Control
                            type="text"
                            value={serverLabel}
                            onChange={(e) => setServerLabel(e.target.value)}
                            maxLength={16}
                            placeholder="Enter server name"
                          />
                        </Col>
                        <Col md={6}>
                          <Button
                            variant="primary"
                            disabled={disableCheckout}
                            onClick={createOrder}
                          >
                            Checkout
                          </Button>
                        </Col>
                      </Row>
                    </Form.Group>
                  </div>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};
